import {
  IconChevronDown,
  IconChevronRight,
  IconDirectionDown,
  IconDotsHorizontal,
} from 'components/icons';
import { useAuthenticationContext } from 'components/providers/authentication-provider';
import { SITE_MAP } from 'globalConstants';
import { FileWithFullUrls, ProjectEnumType } from 'graphql/main/queries';
import { useToast } from 'hooks';
import { includes, isEmpty, isEqual, map } from 'lodash';
import Link from 'next/link';
import { useRouter } from 'next/router';
import queryString from 'query-string';
import { Fragment, MouseEvent } from 'react';
import ToastStack from '../../toast-stack';
import ChildNavigation from './child-navigation';
import RootNavigation from './root-navigation';

export interface NavigationObject {
  name: string;
  pathname?: string;
  isExternal?: boolean;
  children?: RootNavigationObject[];
}

export interface RootNavigationObject extends NavigationObject {
  isExtra?: boolean;
  children?: ChildNavigationObject[];
}

export interface ChildNavigationObject extends NavigationObject {
  children?: ChildNavigationObject[];
}

const Toolbar = () => {
  const { toasts, addToast, removeToast } = useToast();
  const { asPath, pathname, push, reload } = useRouter();
  const { currentUser, clearAuthentication } = useAuthenticationContext();
  const navigations: RootNavigationObject[] = [
    {
      name: 'Trang chủ',
      pathname: '/',
    },
    {
      name: 'Nhà bán',
      pathname: '/nha-dat-ban',
      children: [
        {
          name: 'Bán nhà mặt phố',
          pathname: '/ban-nha-mat-pho',
        },
        {
          name: 'Bán biệt thự, nhà liền kề',
          pathname: '/ban-biet-thu-nha-lien-ke',
        },
        {
          name: 'Bán cao ốc, văn phòng',
          pathname: '/ban-cao-oc-van-phong',
        },
        {
          name: 'Bán khách sạn',
          pathname: '/ban-khach-san',
        },
        {
          name: 'Bán nhà xưởng, kho, kiot',
          pathname: '/ban-nha-xuong-kho-kiot',
        },
        {
          name: 'Bán đất nền',
          pathname: '/ban-dat-nen',
        },
        {
          name: 'Bán căn hộ chung cư',
          pathname: '/ban-can-ho-chung-cu',
        },
        {
          name: 'Bán shophouse',
          pathname: '/ban-shophouse',
        },
        {
          name: 'Bán penthouse',
          pathname: '/ban-penthouse',
        },
        {
          name: 'Bán condotel',
          pathname: '/ban-condotel',
        },
      ],
    },
    {
      name: 'Nhà thuê',
      pathname: '/nha-dat-cho-thue',
      children: [
        {
          name: 'Cho thuê nhà mặt phố',
          pathname: '/cho-thue-nha-mat-pho',
        },
        {
          name: 'Cho thuê biệt thự, nhà liền kề',
          pathname: '/cho-thue-biet-thu-nha-lien-ke',
        },
        {
          name: 'Cho thuê cao ốc, văn phòng',
          pathname: '/cho-thue-cao-oc-van-phong',
        },
        {
          name: 'Cho thuê khách sạn',
          pathname: '/cho-thue-khach-san',
        },
        {
          name: 'Cho thuê nhà xưởng, kho, kiot',
          pathname: '/cho-thue-nha-xuong-kho-kiot',
        },
        {
          name: 'Cho thuê đất nền',
          pathname: '/cho-thue-dat-nen',
        },
        {
          name: 'Cho thuê căn hộ chung cư',
          pathname: '/cho-thue-can-ho-chung-cu',
        },
        {
          name: 'Cho thuê shophouse',
          pathname: '/cho-thue-shophouse',
        },
        {
          name: 'Cho thuê penthouse',
          pathname: '/cho-thue-penthouse',
        },
        {
          name: 'Cho thuê condotel',
          pathname: '/cho-thue-condotel',
        },
        {
          name: 'Cho thuê phòng trọ',
          pathname: '/cho-thue-phong-tro',
        },
      ],
    },
    {
      name: 'Dự án',
      pathname: `/${SITE_MAP.PROJECT.INDEX}`,
      children: [
        {
          name: 'Dự án phức hợp',
          pathname: `/du-an?projectType=${ProjectEnumType.complex}`,
        },
        {
          name: 'Dự án đất nền',
          pathname: `/du-an?projectType=${ProjectEnumType.landProject}`,
        },
        {
          name: 'Dự án khu công nghiệp',
          pathname: `/du-an?projectType=${ProjectEnumType.industrialArea}`,
        },
        {
          name: 'Dự án cao ốc văn phòng',
          pathname: `/du-an?projectType=${ProjectEnumType.buildingProject}`,
        },
        {
          name: 'Dự án trung tâm thương mại',
          pathname: `/du-an?projectType=${ProjectEnumType.shoppingMall}`,
        },
        {
          name: 'Dự án nhà ở xã hội',
          pathname: `/du-an?projectType=${ProjectEnumType.socialHouse}`,
        },
      ],
    },
    {
      name: 'Bản đồ',
      pathname: `/${SITE_MAP.MAP.INDEX}`,
      isExtra: true,
    },
    {
      name: 'Định giá',
      pathname: `/${SITE_MAP.VALUATION.INDEX}`,
      isExtra: true,
    },
    {
      name: 'Khám phá',
      pathname: `/${SITE_MAP.DISCOVERY.INDEX}`,
      isExtra: true,
    },
    {
      name: 'TMĐT',
      pathname: `/${SITE_MAP.E_COMMERCE.INDEX}`,
      isExternal: true,
      isExtra: true,
    },
    {
      name: 'Mạng xã hội',
      pathname: `/${SITE_MAP.SOCIAL_NETWORK.INDEX}/${SITE_MAP.SOCIAL_NETWORK.REAL_ESTATE_POST.INDEX}`,
      isExternal: true,
      isExtra: true,
    },
    {
      name: 'Tin tức',
      pathname: `/${SITE_MAP.BLOG.INDEX}`,
      children: [
        {
          name: 'Kiến thức bất động sản',
          pathname: `/${SITE_MAP.BLOG.INDEX}/kien-thuc-bat-dong-san`,
          children: [
            {
              name: 'Các loại bất động sản',
              pathname: `/${SITE_MAP.BLOG.INDEX}/cac-loai-bat-dong-san`,
            },
            {
              name: 'So sánh các loại bất động sản',
              pathname: `/${SITE_MAP.BLOG.INDEX}/so-sanh-cac-loai-bat-dong-san`,
            },
          ],
        },
        {
          name: 'Thông tin thị trường',
          pathname: `/${SITE_MAP.BLOG.INDEX}/thong-tin-thi-truong`,
          children: [
            {
              name: 'Tin tức BĐS',
              pathname: `/${SITE_MAP.BLOG.INDEX}/tin-tuc-bds`,
            },
            {
              name: 'Proptech Việt Nam',
              pathname: `/${SITE_MAP.BLOG.INDEX}/proptech-viet-nam`,
            },
          ],
        },
        {
          name: 'Phong thủy nhà ở',
          pathname: `/${SITE_MAP.BLOG.INDEX}/phong-thuy-nha-o`,
          children: [
            {
              name: 'Phong thủy phòng ngủ',
              pathname: `/${SITE_MAP.BLOG.INDEX}/phong-thuy-phong-ngu`,
            },
            {
              name: 'Phong thủy phòng khách',
              pathname: `/${SITE_MAP.BLOG.INDEX}/phong-thuy-phong-khach`,
            },
            {
              name: 'Phong thủy phòng tắm',
              pathname: `/${SITE_MAP.BLOG.INDEX}/phong-thuy-phong-tam`,
            },
            {
              name: 'Phong thủy phòng bếp',
              pathname: `/${SITE_MAP.BLOG.INDEX}/phong-thuy-phong-bep`,
            },
            {
              name: 'Phong thủy cửa chính, sân vườn',
              pathname: `/${SITE_MAP.BLOG.INDEX}/phong-thuy-cua-chinh-san-vuon`,
            },
          ],
        },
        {
          name: 'Phong thủy nhà đất',
          pathname: `/${SITE_MAP.BLOG.INDEX}/phong-thuy-nha-dat`,
          children: [
            {
              name: 'Phong thủy vị trí',
              pathname: `/${SITE_MAP.BLOG.INDEX}/phong-thuy-vi-tri`,
            },
            {
              name: 'Phong thủy hình dạng đất',
              pathname: `/${SITE_MAP.BLOG.INDEX}/phong-thuy-hinh-dang-dat`,
            },
          ],
        },
        {
          name: 'Tư vấn đầu tư bất động sản',
          pathname: `/${SITE_MAP.BLOG.INDEX}/tu-van-dau-tu-bat-dong-san`,
          children: [
            {
              name: 'So sánh đầu tư BĐS và các kênh khác',
              pathname: `/${SITE_MAP.BLOG.INDEX}/so-sanh-dau-tu-bds-va-cac-kenh-khac`,
            },
            {
              name: 'Vị trí bất động sản',
              pathname: `/${SITE_MAP.BLOG.INDEX}/vi-tri-bat-dong-san`,
            },
            {
              name: 'Loại hình BĐS đầu tư',
              pathname: `/${SITE_MAP.BLOG.INDEX}/loai-hinh-bds-dau-tu`,
            },
          ],
        },
        {
          name: 'Phong thủy theo tuổi',
          pathname: `/${SITE_MAP.BLOG.INDEX}/phong-thuy-theo-tuoi`,
          children: [
            {
              name: 'Tý',
              pathname: `/${SITE_MAP.BLOG.INDEX}/ty`,
            },
            {
              name: 'Sửu',
              pathname: `/${SITE_MAP.BLOG.INDEX}/suu`,
            },
            {
              name: 'Dần',
              pathname: `/${SITE_MAP.BLOG.INDEX}/dan`,
            },
            {
              name: 'Mão',
              pathname: `/${SITE_MAP.BLOG.INDEX}/mao`,
            },
            {
              name: 'Thìn',
              pathname: `/${SITE_MAP.BLOG.INDEX}/thin`,
            },
            {
              name: 'Tỵ',
              pathname: `/${SITE_MAP.BLOG.INDEX}/ty-1`,
            },
            {
              name: 'Ngọ',
              pathname: `/${SITE_MAP.BLOG.INDEX}/ngo`,
            },
            {
              name: 'Mùi',
              pathname: `/${SITE_MAP.BLOG.INDEX}/mui`,
            },
            {
              name: 'Thân',
              pathname: `/${SITE_MAP.BLOG.INDEX}/than`,
            },
            {
              name: 'Dậu',
              pathname: `/${SITE_MAP.BLOG.INDEX}/dau`,
            },
            {
              name: 'Tuất',
              pathname: `/${SITE_MAP.BLOG.INDEX}/tuat`,
            },
            {
              name: 'Hợi',
              pathname: `/${SITE_MAP.BLOG.INDEX}/hoi`,
            },
          ],
        },
        {
          name: 'Vay vốn mua bất động sản',
          pathname: `/${SITE_MAP.BLOG.INDEX}/vay-von-mua-bat-dong-san`,
        },
        {
          name: 'Thủ tục pháp lý bất động sản',
          pathname: `/${SITE_MAP.BLOG.INDEX}/thu-tuc-phap-ly-bat-dong-san`,
          children: [
            {
              name: 'Kiểm tra quy hoạch',
              pathname: `/${SITE_MAP.BLOG.INDEX}/kiem-tra-quy-hoach`,
            },
            {
              name: 'Lưu ý khi mua bán BĐS',
              pathname: `/${SITE_MAP.BLOG.INDEX}/luu-y-khi-mua-ban-bds`,
            },
          ],
        },
      ],
    },
    {
      name: 'Dịch vụ',
      children: [
        {
          name: 'Văn phòng công chứng',
          pathname: `/${SITE_MAP.NOTARIZATION.INDEX}`,
          isExternal: true,
        },
        {
          name: 'Văn phòng đăng ký đất đai',
          pathname: `/${SITE_MAP.OFFICE_REGISTRATION.INDEX}`,
          isExternal: true,
        },
        {
          name: 'Vay tín dụng, mở thẻ, tài khoản ngân hàng',
          pathname: `/${SITE_MAP.BANK_LOAN.INDEX}`,
          isExternal: true,
        },
      ],
    },
  ];
  const userMenu = [
    {
      name: 'Tin rao đã đăng',
      pathname: `/${SITE_MAP.POSTED_REAL_ESTATE.INDEX}`,
    },
    {
      name: 'Tin rao đã lưu',
      pathname: `/${SITE_MAP.POSTED_REAL_ESTATE.INDEX}/${SITE_MAP.POSTED_REAL_ESTATE.SAVED_REAL_ESTATE}`,
    },
    {
      name: 'Lịch xem nhà',
      hasBorder: true,
      pathname: '/lich-xem-nha',
    },
    {
      name: 'Quản lý tin đầu chủ',
      pathname: `/${SITE_MAP.LEAD_REAL_ESTATE.INDEX}/${SITE_MAP.LEAD_REAL_ESTATE.SAVED_LEAD_REAL_ESTATE}`,
    },
    {
      name: 'Quản lý tài chính',
      pathname: `/${SITE_MAP.FINANCE.INDEX}/${SITE_MAP.FINANCE.EWALLET}`,
    },
    {
      name: 'Quản lý dự án',
      pathname: `/${SITE_MAP.POSTED_PROJECT.INDEX}/${SITE_MAP.POSTED_PROJECT.SAVED_PROJECT}`,
    },
    {
      name: 'Quản lý đơn hàng',
      pathname: `/${SITE_MAP.ORDER.INDEX}`,
    },
    {
      name: 'Quản lý yêu cầu dịch vụ',
      pathname: `/${SITE_MAP.SERVICE_REQUEST.INDEX}/${SITE_MAP.SERVICE_REQUEST.BANK.INDEX}`,
    },
    {
      name: 'Quản lý nâng cấp',
      pathname: `/${SITE_MAP.AGENT_UPGRADE.INDEX}`,
    },
    {
      name: 'Quản lý booking',
      pathname: `/${SITE_MAP.LIST_BOOKING.INDEX}`,
    },
    {
      name: 'Mạng lưới của tôi',
      hasBorder: true,
      pathname: `/${SITE_MAP.MY_NETWORK.INDEX}`,
    },
    {
      name: 'Thông tin của tôi',
      pathname: `/${SITE_MAP.MY_PROFILE.INDEX}`,
    },
    {
      name: 'Đổi mật khẩu',
      hasBorder: true,
      pathname: `/${SITE_MAP.MY_PROFILE.INDEX}/${SITE_MAP.MY_PROFILE.CHANGE_PASSWORD}`,
    },
    {
      name: 'Danh thiếp của tôi',
      hasBorder: true,
      pathname: `/${SITE_MAP.MY_NAME_CARD.INDEX}`,
    },
  ];
  const incomingFeaturePath = ['/lich-xem-nha'];

  const clickIncomingFeature = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    addToast('information', 'Chức năng đang được hoàn thiện!');
  };
  const clickSubChildNavigation = (
    event: MouseEvent<HTMLSpanElement>,
    navigation?: NavigationObject,
  ) => {
    event.preventDefault();
    if (navigation?.pathname) {
      if (navigation.isExternal) {
        window.open(navigation.pathname);
      } else {
        push({
          pathname: navigation.pathname,
        });
      }
    }
  };
  const signIn = () => {
    if (!isEqual(pathname, `/${SITE_MAP.SIGN_IN.INDEX}`)) {
      push({
        pathname: `/${SITE_MAP.SIGN_IN.INDEX}`,
        query: queryString.stringify({ redirect: asPath }, { encode: false }),
      });
    }
  };
  const signOut = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    clearAuthentication?.();
    reload();
  };

  return (
    <div id='toolbar' className='flex items-center space-x-[28px]'>
      {isEmpty(navigations) || (
        <nav>
          <ul className='flex items-center'>
            {map(navigations, (navigation, navigationIndex) => (
              <li key={navigationIndex} className='group/navigation relative'>
                <RootNavigation navigation={navigation}>
                  <span className='whitespace-nowrap font-[600]'>{navigation.name}</span>
                  {navigation.children && <IconChevronDown className='h-[18px] w-[18px]' />}
                </RootNavigation>
                {isEmpty(navigation.children) || (
                  <div className='absolute left-1/2 flex w-max origin-[top_center] -translate-x-1/2 scale-0 opacity-0 transition duration-[200ms] ease-in-out group-hover/navigation:scale-100 group-hover/navigation:opacity-100'>
                    <ul className='mt-[12px] flex flex-col rounded-[4px] bg-paper py-[4px] shadow-8'>
                      {map(navigation.children, (navigation, navigationIndex) => (
                        <li key={navigationIndex}>
                          <ChildNavigation navigation={navigation}>
                            <span>{navigation.name}</span>
                            {isEmpty(navigation.children) || (
                              <Fragment>
                                <IconChevronRight className='h-[18px] min-h-[18px] w-[18px] min-w-[18px]' />
                                <div className='absolute left-[calc(100%-12px)] top-0 flex hidden flex-col rounded-[4px] pl-[4px] group-hover/navigation-child:flex'>
                                  <div className='flex max-h-[212px] min-w-[180px] flex-col flex-wrap rounded-[4px] bg-paper py-[4px] shadow-8'>
                                    {map(navigation.children, (navigation, navigationIndex) => (
                                      <span
                                        key={navigationIndex}
                                        className='flex whitespace-nowrap bg-transparent px-[12px] py-[8px] hover:bg-secondary'
                                        onClick={(event) => {
                                          clickSubChildNavigation(event, navigation);
                                        }}
                                      >
                                        {navigation.name}
                                      </span>
                                    ))}
                                  </div>
                                </div>
                              </Fragment>
                            )}
                          </ChildNavigation>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            ))}
            <li className='group/navigation relative ml-[16px] screen-1366:hidden'>
              <span>
                <IconDotsHorizontal className='min-h-[24px] min-w-[24px] font-[600] text-text' />
              </span>
              <div className='absolute left-1/2 flex w-max origin-[top_center] -translate-x-1/2 scale-0 opacity-0 transition duration-[200ms] ease-in-out group-hover/navigation:scale-100 group-hover/navigation:opacity-100'>
                <ul className='mt-[12px] flex flex-col rounded-[4px] bg-paper py-[4px] shadow-8'>
                  {map(
                    navigations,
                    (navigation, navigationIndex) =>
                      navigation.isExtra && (
                        <li key={navigationIndex}>
                          <ChildNavigation navigation={navigation}>
                            <span>{navigation.name}</span>
                            {isEmpty(navigation.children) || (
                              <Fragment>
                                <IconChevronRight className='h-[18px] min-h-[18px] w-[18px] min-w-[18px]' />
                                <div className='absolute right-[calc(100%-12px)] top-0 flex hidden flex-col rounded-[12px] pr-[16px] group-hover/navigation-child:flex'>
                                  <div className='flex max-h-[212px] min-w-[180px] flex-col flex-wrap rounded-[4px] bg-paper py-[4px] shadow-8'>
                                    {map(navigation.children, (navigation, navigationIndex) => (
                                      <span
                                        key={navigationIndex}
                                        className='flex whitespace-nowrap bg-transparent px-[12px] py-[8px] hover:bg-secondary'
                                        onClick={(event) => {
                                          clickSubChildNavigation(event, navigation);
                                        }}
                                      >
                                        {navigation.name}
                                      </span>
                                    ))}
                                  </div>
                                </div>
                              </Fragment>
                            )}
                          </ChildNavigation>
                        </li>
                      ),
                  )}
                </ul>
              </div>
            </li>
          </ul>
        </nav>
      )}
      <Link
        href={`/${SITE_MAP.POST_REAL_ESTATE.INDEX}`}
        passHref
        className='flex h-[40px] items-center space-x-[4px] rounded-[8px] border border-primary bg-transparent p-[12px] text-primary'
      >
        <span className='whitespace-nowrap font-[600]'>Đăng tin</span>
      </Link>
      {/* <button type='button' className='relative text-primary'>
        <AiOutlineBell className='h-[32px] w-[32px]' />
        <span className='absolute top-[-2px] right-[-2px] flex min-h-[24px] min-w-[24px] items-center justify-center rounded-full border border-paper bg-badge text-[10px] leading-[13px] text-paper'>
          5
        </span>
      </button> */}
      <div>
        {currentUser ? (
          <Link
            href={`/${SITE_MAP.MY_PROFILE.INDEX}`}
            className='group relative flex cursor-pointer items-center space-x-[8px] text-text'
          >
            <div className='h-[32px] w-[32px] overflow-hidden rounded-full'>
              <img
                src={
                  (currentUser.avatar as FileWithFullUrls)?.originalUrl ??
                  '/images/default-avatar.svg'
                }
                alt='avatar'
                loading='lazy'
                className='h-full w-full object-cover'
              />
            </div>
            <span className='max-w-[100px] truncate font-[600]'>{currentUser.fullName}</span>
            <IconDirectionDown className='min-h-[24px] min-w-[24px]' />
            <div className='absolute right-0 top-full flex w-max origin-[top_center] scale-0 opacity-0 transition duration-[200ms] ease-in-out group-hover:scale-100 group-hover:opacity-100'>
              <ul className='mt-[12px] flex w-[200px] flex-col overflow-hidden rounded-[4px] bg-paper py-[4px] shadow-8'>
                {map(userMenu, (userMenuItem, userMenuItemIndex) => (
                  <li key={userMenuItemIndex}>
                    <Link
                      href={userMenuItem.pathname}
                      className={`flex w-full whitespace-nowrap bg-transparent px-[12px] py-[8px] hover:bg-secondary ${
                        userMenuItem.hasBorder ? 'border-b' : null
                      }`}
                      onClick={(event) =>
                        includes(incomingFeaturePath, userMenuItem.pathname) &&
                        clickIncomingFeature(event)
                      }
                    >
                      <span className='font-[500] text-text'>{userMenuItem?.name}</span>
                    </Link>
                  </li>
                ))}
                <li>
                  <button
                    type='button'
                    className='flex w-full whitespace-nowrap bg-transparent px-[12px] py-[8px] hover:bg-secondary'
                    onClick={signOut}
                  >
                    <span className='font-[500] text-text'>Đăng xuất</span>
                  </button>
                </li>
              </ul>
            </div>
          </Link>
        ) : (
          <div className='flex space-x-[4px] font-[600] text-text'>
            <Link href={`/${SITE_MAP.SIGN_UP.INDEX}`}>
              <span className='whitespace-nowrap'>Đăng ký</span>
            </Link>
            <span>/</span>
            <button type='button' onClick={signIn}>
              <span className='whitespace-nowrap'>Đăng nhập</span>
            </button>
          </div>
        )}
      </div>
      <ToastStack toasts={toasts} removeToast={removeToast} />
    </div>
  );
};

export default Toolbar;
